* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    line-height: 1.5;
    font-family: 'Noto Sans KR', sans-serif;
    -webkit-tap-highlight-color: transparent;
}

html {
    --vh: 1vh;
}

body {
    min-height: calc(var(--vh) * 100);
}

/* * {
    outline: solid 1px #2097F6;
} */

:root {
    --gray1: #fcfcfc;
    --gray2: #FAFAFA;
    --gray2-hover: #f1f1f1;
    --gray3: #E7E7E7;
    --gray4: #D8D8D8;
    --gray5: #AAAAAA;
    --gray6: #444650;
    --gray7: #19191C;

    --blue1: #FAFCFF;
    --blue2: #ECF3FE;
    --blue2-hover: #dde8fa;
    --blue3: #C9DEFF;
    --blue4: #77ACFF;
    --blue5: #3359FC;

    --status-red: #ee0000;
    --status-yellow: #FAAF00;
    --status-green: #50e3c2;
    --status-blue: #0b8ffa;

    --status-red-background: rgba(255, 0, 0, 0.1);
    --status-yellow-background: rgb(250, 175, 0, 0.1);
    --status-green-background: rgba(80, 227, 194, 0.1);
    --status-blue-background: rgba(32, 151, 246, 0.1);
}